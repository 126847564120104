<template>
  <div class="item-body" v-if="item">
    <img src="https://res.offertalks.cn/miniapp/icon-pdf-2.png" class="logo" />
    <div class="item-right-div">
      <div class="item-middle-body">
        <div style="display: flex">
          <div class="title">{{ item.title }}</div>
          <div class="tags">
            <span v-for="(tag,index) in getTags()" class="tag" :key="index">
              {{ tag }}
            </span>
          </div>
        </div>
        <div style="display: flex;margin-top: 10px">
          <div class="item-desc">{{ item.content }}</div>
        </div>
      </div>

      <el-button class="item-btn" @click="viewDetail">看一看</el-button>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "InterviewItemTemplate",
  setup() {
    return {
      currentAudio: null
    };
  },
  props: {
    item: {
      type: Object
    },
    isFree: {
      type: Boolean
    },
    openInterview: {
      type: Function,
      default: null
    }
  },
  computed: {
    canSeeDetail() {
      if (this.isFree) {
        return true;
      } else if (this.userInfo?.isVip === 1) {
        return true;
      }
      return false;
    },
    ...mapGetters(["userInfo"])

  },
  methods: {
    getTags() {
      let tags = [];
      if (this.item) {
        if (this.item.jobTags) {
          tags = tags.concat(this.item.jobTags);
        }
        if (this.item.businessTags) {
          tags = tags.concat(this.item.businessTags);
        }
      }
      return tags;
    },
    viewDetail() {
      if (this.openInterview) {
        this.openInterview(this.item);
      }
    },
    playAudio(url) {
      console.log("parent play", url);
      this.currentAudio = url;
    },
    pauseAudio() {
      this.currentAudio = null;
    },
    toSeeDetail() {
      if (this.userInfo?.isVip !== 1) {
        this.UPDATE_VIP_VISIBLE(true);
      }
    },
    ...mapActions(["UPDATE_VIP_VISIBLE"])
  }
};
</script>

<style lang="scss" scoped>
.item-body {
  display: flex;
  margin-bottom: 15px;
  padding-bottom: 20px;
  margin-top: 15px;

  .logo {
    width: 93px;
    height: 93px;
    object-fit: cover;
    border-radius: 4px;
    margin-right: 27px;
  }


  .item-right-div {
    display: flex;
    width: calc(853px - 93px - 27px);
    justify-content: space-between;

    .item-middle-body {
      display: flex;
      flex-direction: column;
    }

    .title {
      font-size: 24px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 33px;
    }

    .tags {
      display: flex;
      flex-direction: row;
      overflow-x: auto;
      margin-left: 20px;
      flex: 1;

      .tag {
        white-space: nowrap;
        flex-direction: column;
        align-items: center;
        padding: 4px 10px;
        background: rgba(0, 82, 254, 0.1);
        border-radius: 8px;
        text-align: center;
        margin-right: 10px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #0052FE;
        line-height: 32px;
      }
    }

    .item-desc {
      flex: 1;
      font-size: 19px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 32px;
    }

    .item-btn {
      background: #0052FE;
      border-radius: 6px;
      height: 53px;
      padding: 13px 20px;
      margin-left: 40px;
      text-align: center;
      font-size: 19px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 27px;
    }
  }

}
</style>