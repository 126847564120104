<template>
  <div>
    <div class="resume-detail-body" >
        <div class="right-private-text">已授权offertalks.cn 盗版必究</div>
        <div style="z-index: 100">
          <div v-if="album" class="resume-header">
            <img class="resume-avatar" :src="album.backgroundImage" />
            <div class="resume-title">
              <span>{{ album.title }}</span>
              <div class="resume-tags">
              <span v-for="(tag,index) in tags" class="tag" :key="index">
                {{ tag }}
              </span>
              </div>
              <img v-if="album.price > 0" class="vip-icon" style="margin-top: 0px" src="https://res.offertalks.cn/miniapp/icon-vip.png" />
            </div>
          </div>

          <div v-if="album" class="resume-item-card">
            <div class="card-content">{{ album.content }}</div>
          </div>

          <div v-if="questionList && questionList.length > 0">
            <div v-for="item in questionList" :key="item.id" class="question">
              <div class="left-row">
                <img src="https://res.offertalks.cn/miniapp/left-headpic.png?x=1" style="margin-right: 20px" />
                <div class="chat-box-left">{{ item.question }}</div>
              </div>
              <div class="right-row">
                <div class="chat-box-right" v-html="item.answer"></div>
                <img src="https://res.offertalks.cn/miniapp/right-headpic.png?x=1" />
              </div>
            </div>
          </div>


        </div>
      </div>

  </div>
</template>

<script>
import { interviewDetail } from "@/apis/interview";

export default {
  name: "InterviewDetail",
  setup() {
    return {
      interview: null,
      questionList: null
    };
  },
  props: {
    album: {
      type: Object
    },
    interviewId: {
      type: Number
    },
    tags: {
      type: Array
    }
  },
  created() {
    this.getInterviewDetail();
  },
  watch: {
    interviewId() {
      this.questionList = null
      this.getInterviewDetail();
    }
  },
  methods: {
    getMaxHeight() {
      return (window.innerHeight * 0.6) + "px";
    },
    getInterviewDetail() {
      interviewDetail(this.interviewId).then(res => {
        this.interview = res.data;
        this.questionList = res.data.questionList;
        this.$forceUpdate();
      });
    }
  }
};
</script>

<style scoped lang="scss">
.resume-detail-body {
  width: 100%;
  padding: 0 20px;

  .right-private-text {
    font-size: 20px;
    width: 100%;
    text-align: right;
  }

  .bottom-private-text {
    width: 100%;
    text-align: end;
    font-size: 20px;
    margin-top: 40px;
  }

  .resume-header {
    display: flex;

    .resume-title {
      margin-left: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 46px;
      flex: 1;
      font-size: 35px;
    }

    .resume-avatar {
      width: 133px;
      height: 187px;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  .resume-item-card {
    margin-top: 20px;

    .card-title {
      width: 100%;
      padding: 4px 0 4px 0;
      background: #F5F9FF;
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #4791FF;
      line-height: 60px;
      display: flex;

      .column_icon {
        width: 10px;
        height: 60px;
        margin-right: 20px;
        background: #4791FF;
      }
    }

    .card-content {
      white-space: pre-line;
      margin-top: 10px;
      font-size: 26px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 48px;

    }
  }

  .resume-tags {
    margin-top: 13px;
    display: flex;
    margin-bottom: 13px;
    flex-direction: row;
    overflow-x: auto;

    .tag {
      white-space: nowrap;
      flex-direction: column;
      align-items: center;
      padding: 10px 20px;
      background: rgba(0, 82, 254, 0.1);
      border-radius: 8px;
      font-size: 24px;
      text-align: center;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #0052FE;
      line-height: 23px;
      margin-right: 12px;
    }
  }

  .question {
    margin-top: 40px;
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 48px;

    img {
      width: 84px;
      height: 84px;
    }

    .left-row {
      display: flex;
      margin-right: 100px;

      span {
        margin-left: 20px;
      }
    }

    .right-row {
      display: flex;
      margin-left: 100px;
      margin-top: 20px;
    }


    .chat-box-left {
      padding: 30px;
      max-width: calc(100% - 55px);
      background: #F6F6F6;
      position: relative;
      border-radius: 8px;
      margin-right: 11px;
    }

    .chat-box-left:before {
      content: "";
      position: absolute;
      right: 100%;
      width: 0;
      height: 0;
      border-top: 9px solid transparent;
      border-right: 8px solid #F6F6F6;
      border-bottom: 9px solid transparent;
      transform: translateY(-50%);

    }

    .chat-box-right {
      margin-left: 30px;
      padding: 30px;
      max-width: calc(100% - 55px);
      background: #A8EA7C;
      position: relative;
      border-radius: 8px;
      margin-right: 30px;
    }

    .chat-box-right:before {
      content: "";
      position: absolute;
      left: 100%;
      width: 0;
      height: 0;
      border-top: 9px solid transparent;
      border-left: 8px solid #A8EA7C;
      border-bottom: 9px solid transparent;
      transform: translateY(-50%);

    }
  }

}
</style>