<template>
    <el-dialog :visible.sync="openItemDetailDialog" width="80%" custom-class="pdfDialog"
    >
      <vue-html2pdf
        :show-layout="false"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="false"
        :pdf-quality="2"
        pdf-format="a4"
        pdf-content-width="100%"
        :manual-pagination="true"
        ref="interviewPdfRef"
        :html-to-pdf-options="pdfOptions"
        @startPagination="startPagination"
        @hasDownloaded="stopPagination"
        class="html2pdf"
      >
        <section slot="pdf-content">
          <resume-detail v-if="itemType === 'resume'" :resume-id="itemDetail.id" ref="resumeDetailRef" :album="album"/>
          <interview-detail v-else-if="itemDetail && itemType === 'interview'" :album="album"
                            :interview-id="itemDetail.id" :tags="getTags()" />
          <knowledge-detail v-else-if="itemDetail && itemType === 'knowledge'" :album="album"
                            :knowledge-id="itemDetail.id" :tags="getTags()" />
        </section>
      </vue-html2pdf>
      <div slot="footer">
        <el-button type="primary" class="vip-btn"
                   @click="download(downloadItem,itemType,itemDetail.id)"
                   :loading="startGenPdf">立即下载
        </el-button>
      </div>
    </el-dialog>
</template>

<script>
import ResumeDetail from "@/components/Article/ResumeDetail";
import InterviewDetail from "@/components/InterviewDetail";
import KnowledgeDetail from "@/components/KnowledgeDetail";
import { download } from "@/apis/download";
import { mapActions, mapGetters } from "vuex";
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: "PreviewItemDialog",
  components: { KnowledgeDetail, InterviewDetail, ResumeDetail,VueHtml2pdf },
  props: {
    openItemDetailDialog: {
      type: Boolean,
      default: false
    },
    itemDetail:{
      type:Object
    },
    itemType:{
      type:String
    },
    album:{
      type:Object
    }
  },
  watch:{
    itemDetail(newVal){
      this.pdfOptions.filename = newVal.title + ".pdf"
    }
  },
  data() {
    return {
      startGenPdf: false,
      pdfOptions: {
        filename: null,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2, letterRendering: true, useCORS: true },
        jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
        pagebreak: { mode: ["avoid-all", "css", "legacy"] },
        margin: [10, 20, 20, 20]
      }
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  methods: {
    getTags() {
      return null
    },
    getMaxHeight() {
      return (window.innerHeight * 0.6) + "px";
    },
    startPagination() {
      this.startGenPdf = true;
      this.$forceUpdate();
    },
    stopPagination() {
      this.startGenPdf = false;
      this.$forceUpdate();
    },
    download(callbackFun, contentType, contentId) {
      if (this.userInfo?.isVip === 0) {
        //非会员 不允许下载
        this.UPDATE_VIP_VISIBLE(true);
        return;
      }
      download(contentType, contentId).then(res => {
        if (res.data) {
          callbackFun();
        } else {
          //会员下载失败
          this.$message.error("超过下载次数限制，请联系客服为你开通高级VIP权限");
          this.UPDATE_WECHAT_SERVICE_VISIBLE(true);
        }
      });
    },
    downloadItem() {
      this.$refs.interviewPdfRef.generatePdf();
      // this.getPdf(this.itemDetail.title,'interviewRef')
    },
    ...mapActions(["UPDATE_LOGIN_VISIABLE", "UPDATE_VIP_VISIBLE","UPDATE_WECHAT_SERVICE_VISIBLE"])
  }
};
</script>
<style lang="scss" scoped>

/deep/ .pdfDialog .el-dialog__body{
  padding-left: 40px !important;
  padding-right: 40px !important;
  max-height: calc(70vh);
  overflow: auto;
}
.html2pdf{
  overflow: auto;
}
</style>
