<template>
  <div>
    <div class="resume-detail-body" ref="resumeDetailBody" id="pdfContent">
      <div class="right-private-text">已授权offertalks.cn 盗版必究</div>
      <div v-if="resume">
        <div class="resume-header">
          <img class="resume-avatar" :src="resume.avatar" />
          <div class="resume-title">
            <div>{{ resume.title }}</div>
            <div class="resume-tags">
              <span v-for="(tag,index) in getResumeTags()" class="tag" :key="index">
                {{ tag }}
              </span>
            </div>
            <img v-if="album && album.price > 0" class="vip-icon" style="margin-top: 0px" src="https://res.offertalks.cn/miniapp/icon-vip.png"/>
          </div>
        </div>

        <div class="resume-item-card">
          <div class="card-title">
            <div class="column_icon"></div>
            <div>个人总结</div>
          </div>
          <div class="card-content">{{ resume.reason }}</div>
        </div>

        <div class="resume-item-card" v-if="resume.shixiListData">
          <div class="card-title">
            <div class="column_icon"></div>
            <div>实习经历</div>
          </div>
          <div v-for="(item,index) in resume.shixiListData" class="card-content" :key="index">
            <div class="company_job">
              <div>{{ item.relateCompany.label }}</div>
              <div style="margin-left: 10px">{{ item.jobTitle }}</div>
            </div>

            <div class="taro_html" v-html="item.experienceContent"></div>
          </div>
        </div>

        <div class="resume-item-card" v-if="resume.workListData">
          <div class="card-title">
            <div class="column_icon"></div>
            <div>工作经历</div>
          </div>
          <div v-for="(item,index) in resume.workListData" class="card-content" :key="index">
            <div class="company_job">
              <div>{{ item.relateCompany.label }}</div>
              <div style="margin-left: 10px">{{ item.jobTitle }}</div>
            </div>
            <div class="taro_html" v-html="item.experienceContent"></div>
          </div>
        </div>

        <div class="resume-item-card" v-if="resume.projectListData">
          <div class="card-title">
            <div class="column_icon"></div>
            <div>项目经历</div>
          </div>
          <div v-for="(item,index) in resume.projectListData" class="card-content" :key="index">
            <div class="company_job">
              <div>{{ item.relateCompany.label }}</div>
              <div style="margin-left: 10px">{{ item.jobTitle }}</div>
            </div>

            <div class="taro_html" v-html="item.experienceContent"></div>
          </div>
        </div>
      </div>

      <div class="bottom-private-text">公众号：OfferTalks 小程序：OfferTalks</div>


    </div>
  </div>

</template>

<script>
import { getResumeDetail } from "@/apis/resume";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ResumeDetail",
  props: {
    resumeId: {
      type: Number
    },
    album: {
      type: Object
    },
  },
  data() {
    return {
      resume: null
    };
  },
  mounted() {
    console.log("resumeDetail mounted", this.resumeId);
    if (this.resumeId) {
      this.getResumeDetail();
    }
  },
  watch: {
    resumeId(newVal) {
      console.log("resumeDetail watch", newVal);
      this.getResumeDetail();
    }
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  methods: {
    getResumeDetail() {
      getResumeDetail(this.resumeId).then(res => {
        this.resume = res.data;
        console.log("resume", this.resume);
      });
    },
    getResumeTags() {
      let tags = [];
      if (this.resume) {
        if (this.resume.jobTags) {
          tags = tags.concat(this.resume.jobTags);
        }
        if (this.resume.businessTags) {
          tags = tags.concat(this.resume.businessTags);
        }
      }
      return tags;
    },
    // downloadResume() {
    //   if(this.userInfo?.isVip === 0){
    //     //非会员 不允许下载
    //     this.UPDATE_VIP_VISIBLE(true);
    //     return
    //   }
    //   download('resume' , this.resumeId).then(res=>{
    //     if(res.data){
    //       this.getPdf(this.resume.title)
    //     }else{
    //       //会员下载失败
    //       this.$message.error('超过下载次数限制，请联系客服为你开通高级VIP权限');
    //       this.UPDATE_WECHAT_SERVICE_VISIBLE(true)
    //     }
    //   })
    // },
    ...mapActions(["UPDATE_LOGIN_VISIABLE", "UPDATE_VIP_VISIBLE","UPDATE_WECHAT_SERVICE_VISIBLE"])

  }
};
</script>

<style scoped lang="scss">
.resume-detail-body {
  width: 100%;
  padding: 0 20px;
  //overflow-y: auto;
  //height: 70vh;

  .right-private-text{
    position: absolute;
    right: 60px;
    font-size: 20px;
  }

  .bottom-private-text{
    width: 100%;
    text-align: end;
    font-size: 20px;
    margin-top: 40px;
  }

  .resume-header {
    display: flex;

    .resume-title {
      margin-left: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 46px;
      flex: 1;
      font-size: 35px;
    }

    .resume-avatar {
      width: 133px;
      height: 187px;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  .resume-item-card {
    margin-top: 20px;

    .card-title {
      width: 100%;
      padding: 4px 0 4px 0;
      background: #F5F9FF;
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #4791FF;
      line-height: 60px;
      display: flex;

      .column_icon {
        width: 10px;
        height: 60px;
        margin-right: 20px;
        background: #4791FF;
      }
    }

    .card-content {
      white-space: pre-line;
      margin-top: 10px;
      font-size: 26px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 48px;

      .company_job {
        display: flex;
        margin: 10px 0;
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 46px;
      }
    }
  }

  .resume-tags {
    margin-top: 13px;
    display: flex;
    margin-bottom: 13px;
    flex-direction: row;
    overflow-x: auto;

    .tag {
      white-space: nowrap;
      flex-direction: column;
      align-items: center;
      padding: 10px 20px;
      background: rgba(0, 82, 254, 0.1);
      border-radius: 8px;
      font-size: 24px;
      text-align: center;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #0052FE;
      line-height: 23px;
      margin-right: 12px;
    }
  }
}
</style>